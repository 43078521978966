<!-- @format -->
<template>
  <div>
    <div class="mynavbr">
      <div class="title-div">
        <!-- <div class="menubar fas fa-bars" @click="toggleSideBar()"></div> -->
        <img src="../assets/complogo.jpg" class="topbarlogo" />
        &nbsp;&nbsp;<router-link to="/dashboard" class="title"
          >Dashboard</router-link
        >
      </div>
      <div class="logout-div">
        <span class="logout" @click="toggleLogout"
          ><span class="username">({{ userName }})</span> Logout</span
        >
      </div>
    </div>
    <logout-box
      v-show="logoutbox"
      @cancel="toggleLogout"
      @logout="logoutBtn"
    ></logout-box>
  </div>
</template>

<script>
import LogoutBox from "./LogoutBox.vue";
export default {
  emits: ["toggleBar"],
  components: {
    LogoutBox,
  },
  data() {
    return {
      logoutbox: false,
      userName: this.$store.state.userName,
    };
  },
  methods: {
    toggleLogout() {
      this.logoutbox = !this.logoutbox;
    },
    logoutBtn() {
      localStorage.removeItem("Uname");
      localStorage.removeItem("Uid");
      localStorage.removeItem("Auth");
      localStorage.removeItem("DepId");
      localStorage.removeItem("DepDes");
      localStorage.removeItem("StoreFlg");
      this.$router.push("/");
    },
    toggleSideBar() {
      this.$emit("toggleBar");
    },
  },
};
</script>

<style scoped>
.mynavbr {
  position: fixed;
  width: 100%;
  top: 0px;
  display: block;
  box-shadow: 0px 1px 8px 2px lightgray;
  text-align: left;
  padding: 15px;
  z-index: 999;
  background-color: white;
}

.topbarlogo {
  height: 35px;
}
.mynavbr .title-div,
.logout-div {
  /* width: calc(50% - 20px); */
  display: inline-block;
  position: relative;
  /* border: 1px solid lightgray; */
}
.title-div {
  text-align: left;
  left: 5px;
}
.title {
  text-decoration: none;
  color: slategray;
  font-size: 18px;
  font-weight: 600;
}
.logout-div {
  float: right;
  text-align: right;
  right: 10px;
}
.logout {
  font-size: 15px;
  font-weight: 700;
  color: slategray;
  cursor: pointer;
}
.logout:hover {
  color: #2c3e50;
}

.username {
  position: relative;
  color: #2c3e50;
  margin-right: 5px;
}

.fa-bars {
  display: none;
}

@media only screen and (max-width: 600px) {
  .menubar {
    display: contents;
    right: 15px;
    cursor: pointer;
    padding: 3px;
    background-color: salmon;
  }
  .fas .fa-bars :hover {
    color: salmon;
    background-color: rgb(221, 45, 45);
    border-radius: 25px;
  }

  .topbarlogo {
    height: 25px;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
  }
  .logout {
    font-size: 12px;
    font-weight: 400;
  }
}
</style>
