<!-- @format -->

<template>
  <div
    class="alert-box d-flex align-items-center"
    :class="alertType"
    role="alert"
  >
    <div>
      <span class="fas" :class="geticonClass"></span>&nbsp;&nbsp;&nbsp;{{
        alertText
      }}
    </div>
  </div>
</template>
<script>
export default {
  props: ["alertType", "alertText"],
  data() {
    return {
      alertClass: this.alertType,
    };
  },
  computed: {
    geticonClass: function () {
      var alerticon = "";
      if (this.alertType == "success") {
        alerticon = "fa-check-circle";
      }
      if (this.alertType == "danger") {
        alerticon = "fa-times-circle";
      }
      if (this.alertType == "warn") {
        alerticon = "fa-exclamation-triangle";
      }

      return alerticon;
    },
  },
};
</script>

<style scoped>
.alert-box {
  position: fixed;
  top: 65px;
  float: right;
  right: 35px;
  width: 350px;
  height: fit-content;
  z-index: 99999;
  padding: 15px;
  border-radius: 6px;
  color: white;
}
.danger {
  border: 2px solid #a80808;
  background-color: #d34747;
}

.success {
  border: 2px solid #20751d;
  background-color: #198754;
}
.warn {
  border: 2px solid #756f1d;
  background-color: #ccc125;
}
.info {
  border: 2px solid #1d4075;
  background-color: #255acc;
}
</style>
