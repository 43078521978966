<template>
  <div class="bgcolor" v-show="showForm == true"></div>
  <div class="form-div" id="detail-div" v-show="showForm == true">
    <div class="title-bar">
      <div class="title-text">Invoice Details</div>
      <div class="close-btn" @click="toggleForm">
        <span class="fas fa-times-circle"></span>
      </div>
    </div>
    <!--- TITLE BAR ENDS -->

    <div class="content-area">
      <div class="grid-area">
        <card-loader v-if="loading"></card-loader>
        <div v-else>
          <div>
            <label>Inv.No.:&nbsp;</label><b>{{ ser + "/" + docno }}</b>
            &nbsp;&nbsp;&nbsp;
            <label>Outlet :&nbsp;</label><b>{{ outletName }}</b>
          </div>
          <br />
          <table border="1">
            <thead>
              <tr>
                <th>#</th>
                <th>Item Name</th>
                <th v-if="!cancelyn" class="toright">Qty.</th>
                <th v-if="!cancelyn" class="toright">Rate</th>
                <th v-if="!cancelyn" class="toright">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in mstList" :key="row.uid">
                <td>{{ index + 1 }}</td>
                <td>{{ row.pname }}</td>
                <td v-if="!cancelyn" class="toright">{{ row.qty }}</td>
                <td v-if="!cancelyn" class="toright">{{ row.rate }}</td>
                <td v-if="!cancelyn" class="toright">
                  {{ row.amount }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2" class="toright"></td>
                <td v-if="!cancelyn" class="toright">
                  {{ getTotal(mstList, "qty") }}
                </td>
                <td v-if="!cancelyn" class="toright">&nbsp;</td>
                <td v-if="!cancelyn" class="toright">
                  {{ getTotal(mstList, "amount") }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <!--- CONTENT AREA ENDS -->
    <br />
    <br />
  </div>
  <!--- MAIN DIV ENDS -->
</template>
<script>
import CardLoader from "@/components/CardLoader.vue";
import axios from "axios";
export default {
  props: ["tc", "ser", "docno", "outletId", "outletName", "cancelyn"],
  emits: ["close-form"],
  components: {
    CardLoader,
  },
  data() {
    return {
      mstList: [],
      loading: false,
      userId: this.$store.state.userId,
      userName: this.$store.state.userName,
      showForm: false,
    };
  },
  methods: {
    async getDetail() {
      this.loading = true;
      this.mstList = [];
      await axios
        .post("reports.php", {
          optn: "invdet",
          tc: this.tc,
          ser: this.ser,
          docno: this.docno,
          uid: this.userId,
          uname: this.userName,
          outletid: this.outletId,
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.mstList = resp.List;
          } else {
            console.log("Error:" + this.ErrorMsg);
          }
          this.loading = false;
        });
    },
    getTotal(arr, field) {
      var i = 0;
      var total = 0;
      for (i = 0; i < arr.length; i++) {
        var myval = arr[i];
        total += parseFloat(myval[field]);
      }
      return total.toFixed(2);
    },
    toggleForm() {
      this.showForm = !this.showForm;
      this.$emit("close-form");
    },
  },
};
</script>
<style>
@import "../css/main.css";

:root {
  --formcolor: #4d1f01;
}
</style>
<style scoped>
#detail-div {
  position: fixed;
  z-index: 9999;
  top: 120px;
  width: 100%;
  left: -5px;
  right: 0px;
  border-color: var(--formcolor);
}
#detail-div .title-bar {
  background-color: var(--formcolor);
}
#detail-div .grid-area table {
  border-color: var(--formcolor);
}
#detail-div .grid-area table thead,
.grid-area table tfoot {
  background-color: var(--formcolor);
}

.bgcolor {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.404);
}
</style>
