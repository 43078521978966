<!-- @format -->

<template>
  <div class="form-div">
    <div class="title-bar">
      <div class="title-text">User OTP List</div>
      <div class="close-btn">
        <router-link to="/dashboard">
          <span class="fas fa-times-circle"></span
        ></router-link>
      </div>
    </div>
    <div class="content-area">
      <div class="grid-area">
        <card-loader v-if="loading"></card-loader>
        <table border="1" v-else>
          <thead>
            <tr>
              <th>User Name</th>
              <th class="tocenter">Login OTP</th>
              <th class="tocenter" v-if="userFlag != 'B'">FOC OTP</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in mstList" :key="row.uid">
              <td>{{ row.uid }}</td>
              <td class="tocenter">{{ row.ucode }}</td>
              <td class="tocenter" v-if="userFlag != 'B'">{{ row.rem }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2"></td>
              <td v-if="userFlag != 'B'"></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import CardLoader from "@/components/CardLoader.vue";
import axios from "axios";
export default {
  components: {
    CardLoader,
  },
  data() {
    return {
      formId: "022",
      optn: "Add",
      permission: [],
      search: "",
      mstList: [],
      userId: this.$store.state.userId,
      userName: this.$store.state.userName,
      userFlag: this.$store.state.userFlag,
      errMsg: "",
      errType: "",
      loading: false,
      mdate1: new Date().toJSON().slice(0, 10).replace("/", "-"),
      mdate2: new Date().toJSON().slice(0, 10).replace("/", "-"),
      showBox: false,
      strackno: 0,
    };
  },
  methods: {
    async filterRec() {
      this.loading = true;
      await axios
        .post("users.php", {
          optn: "List",
          msearch: this.search,
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.mstList = resp.List;
          } else {
            console.log("Error:" + this.ErrorMsg);
          }
        });
      this.loading = false;
    },
  },
  async mounted() {
    this.filterRec();
  },
};
</script>
<style>
@import "../css/main.css";
</style>
