import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import MandatoryIcon from "./components/MandatoryIcon.vue";
import AlertBox from "./components/AlertBox.vue";
import CardLoader from "./components/CardLoader.vue";
import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";


// axios.defaults.baseURL = "http://192.168.18.51:8180/HAVELI/havelidashboard/src/php/";
axios.defaults.baseURL = "http://haveli.kvrtechnologies.xyz/php/";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(Vue3SimpleHtml2pdf);
app.component("MandatoryIcon", MandatoryIcon);
app.component("AlertBox", AlertBox);
app.component("CardLoader", CardLoader);

app.mount("#app");
