<!-- @format -->

<template>
  <top-bar v-show="isLogin" @toggleBar="toggleBar"> </top-bar>
  <div class="main-div" id="#maindiv">
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
import TopBar from "@/components/TopBar.vue";
export default {
  name: "HomeView",
  components: {
    TopBar,
  },
  data() {
    return {
      isLogin: true,
      StoreUser: this.$store.state.storeFlg,
    };
  },
  methods: {
    // login(xresp) {
    //   console.log(xresp);
    //   if (xresp.isAuth == true) {
    //     localStorage.setItem("Uname", xresp.Uname);
    //     localStorage.setItem("Auth", xresp.isAuth);
    //     this.isLogin = xresp.isAuth;
    //   }
    // },
    toggleBar() {
      if (this.$refs.sidebar.showSidebar == "") {
        this.$refs.sidebar.showSidebar = "show-sidebar";
      } else {
        this.$refs.sidebar.showSidebar = "";
      }
    },
  },
  activated() {},
  mounted() {
    if (this.$store.state.isAuth != null) {
      // this.isLogin = this.$store.state.isAuth;
    } else {
      // this.isLogin = false;
    }
  },
};
</script>
<style scoped>
.main-div {
  position: relative;
  padding: 0px;
  margin: 0px;
  top: 80px;
  max-height: 100vh;
  text-align: left;
  justify-content: left;
}

@media only screen and (max-width: 600px) {
  .main-div {
    top: 50px;
  }
}
</style>
