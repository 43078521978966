<template>
  <div class="dash-div">
    <div>
      <base-card
        v-if="UserFlag == 'A'"
        :CardTitle="'OUTLET  WISE SALE'"
        @click="toPage('outletsale')"
      ></base-card>

      <base-card
        :CardTitle="'DATE WISE SALE '"
        @click="toPage('datewise')"
      ></base-card>
      <base-card
        :CardTitle="'POS  WISE SALE'"
        @click="toPage('poswise')"
      ></base-card>

      <base-card
        :CardTitle="'CUISINE WISE SALE'"
        @click="toPage('cusinewise')"
      ></base-card>

      <base-card
        :CardTitle="'SALE REPORT DETAIL'"
        @click="toPage('salerep')"
      ></base-card>
      <base-card
        :CardTitle="'CANCEL BILL REPORT'"
        @click="toPage('canbillrep')"
      ></base-card>
      <base-card
        :CardTitle="'CANCEL KOT REPORT'"
        @click="toPage('cankotrep')"
      ></base-card>
      <base-card
        :CardTitle="'CONTACT DETAIL REPORT'"
        @click="toPage('contactrep')"
      ></base-card>
      <base-card
        :CardTitle="'FEEDBACK REPORT'"
        @click="toPage('feedbackrep')"
      ></base-card>
    </div>
  </div>
</template>

<script>
import BaseCard from "../components/BaseCard.vue";
export default {
  components: {
    BaseCard,
  },
  data() {
    return {
      UserFlag: this.$store.state.userFlag,
      userName: this.$store.state.userName,
    };
  },
  methods: {
    toPage(xpg) {
      this.$router.push("/" + xpg);
    },
  },
};
</script>
<style scoped>
.dash-div {
  position: relative;
  top: 20px;
  text-align: center;
  height: fit-content;
}
.dash-div div {
  position: relative;
  display: inline-block;
  margin-top: 15px;
  margin-left: 15px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 600px) {
  .dash-div {
    top: 0px;
  }
  .dash-div div {
    position: relative;
    margin-left: -2px;
    margin-top: 2px;
  }
}
</style>
