/** @format */

import { createStore } from "vuex";

export default createStore({
  state: {
    isAuth: localStorage.getItem("Auth"),
    userName: localStorage.getItem("Uname"),
    userId: localStorage.getItem("Uid"),
    userFlag: localStorage.getItem("UserFlag"),
    posId: localStorage.getItem("PosId"),
    
  },
  mutations: {},
  actions: {},
  modules: {},
});
