import axios from "axios";
export default {
  getPermissions:async function (xuid, xformId) {
    var tbldata = {
      add: false,
      edit: false,
      delete: false,
    };
    await axios
      .post("users.php", {
        optn: "CheckPerm",
        uid: xuid,
        menuid: xformId,
      })
      .then((response) => {
        var resp = response.data.List;
        var data = resp[0];
        if (data.aflg == 1) {
          tbldata.add = true;
        }
        if (data.mflg == 1) {
          tbldata.edit = true;
        }
        if (data.dflg == 1) {
          tbldata.delete = true;
        }
      });

    return tbldata;
  },
  tobrit:function(xdt){
    var mdt=xdt;
    var yy=mdt.substr(0,4);
    var mm=mdt.substr(5,2);
    var dd=mdt.substr(8,2);
    var ndt=dd+'/'+mm+'/'+yy;
    return ndt;
  }
};
