<!-- @format -->

<template>
  <div class="form-div">
    <div class="title-bar">
      <div class="title-text">{{ caption }}</div>
      <div class="close-btn">
        <router-link to="/dashboard">
          <span class="fas fa-times-circle"></span
        ></router-link>
      </div>
    </div>
    <div class="content-area">
      <div class="filter-area">
        <div class="dt-filter">
          <div class="form-group">
            <label for="mdate1">From Date:</label>
            <input type="date" class="form-control dt" v-model="mdate1" />
          </div>

          <div class="form-group">
            <label for="mdate2">To Date:</label>
            <input type="date" class="form-control dt" v-model="mdate2" />
          </div>

          <!-- Filter dt-Area Ends -->
        </div>

        <div class="form-group" v-if="UserFlag == 'A'">
          <label for="mdate2">Outlet Name:</label>
          <select class="form-select" v-model="outletId">
            <option value="ALL">--Select Outlet--</option>
            <option
              v-for="opt in outlets"
              :value="opt.outlet_id"
              :key="opt.outlet_id"
            >
              {{ opt.outlet_name }}
            </option>
          </select>
        </div>
        <br />
        <input
          type="text"
          class="form-control"
          v-model="search"
          @keydown="serachWord"
          placeholder="Search"
        />

        <div class="form-group filter-btn">
          <button type="button" class="btn btn-primary" @click="filterRec">
            <span class="fas fa-filter"></span>
            Filter
          </button>
          &nbsp;
          <button type="button" class="btn btn-secondary" @click="clearFilter">
            <span class="fas fa-filter">
              <span class="fas fa-slash fa-inverse"></span>
            </span>
            Clear Filter
          </button>
          &nbsp;
          <button type="button" class="btn btn-warning" @click="exportPdf">
            <span class="fas fa-file-excel"></span>
            Export
          </button>
        </div>
        <!-- <input
          type="text"
          class="form-control searchbox"
          placeholder="Search"
          v-model="search"
          @keyup="filterRec"
          ref="search"
          autofocus
        /> -->
      </div>
      <vue3-simple-html2pdf
        ref="vue3SimpleHtml2pdf"
        :options="pdfOptions"
        :filename="exportFilename"
      >
        <div class="cusine-grid">
          <div class="pdf-title" v-if="exporting">
            {{ caption }} From:{{ repdt1 }} To:
            {{ repdt2 }}
          </div>
          <card-loader v-if="loading"></card-loader>
          <table border="1" v-else>
            <tbody v-for="row in mstList.outlets" :key="row.uid">
              <tr>
                <td colspan="5">
                  <h3>
                    <u>{{ row.outlet_name }}</u>
                  </h3>
                  <table>
                    <tr
                      colspan="5"
                      v-for="crow in row.cusine"
                      :key="crow.fcdes"
                    >
                      <td>
                        <h5>{{ crow.fcdes }} (Rs.{{ crow.amount }})</h5>
                        <table class="item-table">
                          <tr>
                            <th class="toleft">Item Name</th>
                            <th class="toright">Qty.</th>
                            <th class="toright">Amount</th>
                          </tr>
                          <tr
                            v-for="irow in SortedArray(crow.items)"
                            :key="irow.index"
                          >
                            <td class="toleft">{{ irow.pname }}</td>
                            <td class="tocenter">{{ Math.round(irow.qty) }}</td>
                            <td class="toright">{{ irow.amount }}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- You can loop to display page number as you want -->
        <div class="html2pdf__page-number">1</div>

        <!-- Break page pdf -->
        <div class="html2pdf__page-break"></div>
      </vue3-simple-html2pdf>
    </div>
  </div>
</template>

<script>
import CardLoader from "@/components/CardLoader.vue";
import MyFun from "../js/MyFun.js";

import axios from "axios";
export default {
  components: {
    CardLoader,
  },
  data() {
    return {
      formId: "022",
      optn: "Add",
      permission: [],
      search: "",
      mstList: [],
      mainList: [],
      userId: this.$store.state.userId,
      userName: this.$store.state.userName,
      UserFlag: this.$store.state.userFlag,
      errMsg: "",
      errType: "",
      loading: false,
      mdate1: new Date().toJSON().slice(0, 10).replace("/", "-"),
      mdate2: new Date().toJSON().slice(0, 10).replace("/", "-"),
      repdt1: "",
      repdt2: "",
      showBox: false,
      strackno: 0,
      outlets: [],
      mytimeout: null,
      outletId: this.$store.state.posId,
      caption: "Cuisine Wise Sale",
      exportFilename: "Cusine_wise_Sale.pdf",
      pdfOptions: {
        margin: 15,
        image: {
          type: "jpeg",
          quality: 1,
        },
        html2canvas: { scale: 3 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "p",
        },
      },
      exporting: false,
    };
  },
  methods: {
    async filterRec() {
      this.loading = true;

      await axios
        .post("reports.php", {
          optn: "cusinewise",
          mdate1: this.mdate1,
          mdate2: this.mdate2,
          msearch: this.search,
          uid: this.userId,
          uname: this.userName,
          outletid: this.outletId,
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.mstList = resp.List;
          } else {
            console.log("Error:" + this.ErrorMsg);
          }
        });
      this.loading = false;
    },
    serachWord() {
      if (this.mytimeout != null) {
        clearTimeout(this.mytimeout);
      }

      this.mytimeout = setTimeout(() => {
        this.filterRec();
      }, 500);
    },
    async getOutlets() {
      await axios
        .post("reports.php", {
          optn: "outletlist",
          uid: this.userId,
          uname: this.userName,
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.outlets = resp.List;
          } else {
            console.log("Error:" + this.ErrorMsg);
          }
        });
    },
    async exportPdf() {
      this.exporting = true;
      await this.$refs.vue3SimpleHtml2pdf.download();
      this.exporting = false;
    },
    getTotal(arr, field) {
      var i = 0;
      var total = 0;
      for (i = 0; i < arr.length; i++) {
        var myval = arr[i];
        total += parseFloat(myval[field]);
      }
      return total.toFixed(2);
    },
    SortedArray(xarr) {
      xarr.sort(function (a, b) {
        return b.qty - a.qty;
      });
      return xarr;
    },
  },
  async mounted() {
    this.filterRec();
    this.getOutlets();
    this.repdt1 = MyFun.tobrit(this.mdate1);
    this.repdt2 = MyFun.tobrit(this.mdate2);
  },
  watch: {
    mdate1: function (val) {
      this.repdt1 = MyFun.tobrit(val);
    },
    mdate2: function (val) {
      this.repdt2 = MyFun.tobrit(val);
    },
  },
};
</script>
<style>
@import "../css/main.css";
</style>
<style scoped>
.cusine-grid {
  position: relative;
  width: 100%;
  overflow: auto;
  margin-bottom: 8px;
}
.cusine-grid table {
  width: 100%;
}
.item-table {
  width: 100%;
  border-bottom: 1px solid #242424;
  margin-bottom: 10px;
}
.item-table tr th {
  border: 1px solid #242424;
  color: white;
  background-color: #242424;
}
.item-table tr:hover {
  background-color: lightgray;
}
</style>
