<!-- @format -->

<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  methods: {
    checkSession() {
      var Uid = localStorage.getItem("Uid");
      if (Uid == null) {
        this.$router.replace("/");
      }
    },
  },
  mounted() {
    setInterval(() => {
      this.checkSession();
    }, 500);
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  background-position: center;
  background-repeat: no-repeat;
  background-position-y: 0px;
  background-size: cover;
  margin: 0px;
  padding: 0px;
  clear: both;
  background-color: rgb(240, 239, 239);
}

.tocenter {
  text-align: center;
}

.toright {
  text-align: right;
}

.toleft {
  text-align: left;
}

.fa-edit:hover {
  color: rgb(74, 64, 212);
}

.fa-trash:hover {
  color: rgb(236, 47, 63);
}

.exportlink {
  display: block;
  text-align: right;
}
.exportlink a {
  font-weight: bold;
  color: green;
  text-decoration: none;
}
.exportlink a:hover {
  color: darkgreen;
  text-decoration: underline;
}
.dt {
  width: 155px;
}

@media only screen and (max-width: 600px) {
  body {
    background-repeat: repeat;
  }
}
</style>
