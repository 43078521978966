<template>
  <div class="base-card">{{ CardTitle }}</div>
</template>

<script>
export default {
  props: ["CardTitle", "CardLink"],
};
</script>
<style scoped>
.base-card {
  position: relative;
  display: inline-block;
  top: 0px;
  width: 300px;
  height: 100px;
  font-size: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 1px lightgray;
  text-align: center;
  padding: 20px;
  cursor: pointer;
}

.base-card:hover {
  background-color: whitesmoke;
}

@media only screen and (max-width: 600px) {
  .base-card {
    left: 0px;
    right: 0px;
    top: 25px;
    width: 95%;
    font-size: 20px;
    height: fit-content;
  }
}
</style>
