<!-- @format -->

<template>
  <div>
    <login-form></login-form>
  </div>
</template>
<script>
import LoginForm from "../components/LoginForm.vue";
export default {
  components: {
    LoginForm,
  },
};
</script>
